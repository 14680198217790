@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

@layer base {
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 bg-black text-white font-semibold hover:bg-gray-800 transition-colors duration-300;
  }
}

@layer utilities {

  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }

  .no-scrollbar {
      -ms-overflow-style: none;  
      scrollbar-width: none; }
}
